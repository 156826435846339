import React from "react";
import Layout from "../components/layout";
import Teaser from "../components/teaser";
import styles from './archiveTemplate.module.css';
import { useStaticQuery, graphql } from "gatsby";

export default (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "silbar.jpg" }) {
        childImageSharp {
          fluid(
            quality: 50,
            maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
    }
  `);

  const history = [];
  props.stories.map(post => {
    history.push(<Teaser key={post.node.frontmatter.path} post={post.node} />);
  });

  return (
    <>
      <Layout coverImage={data.file.childImageSharp.fluid}>
        <article className={styles.archive}>{history}</article>
      </Layout>
    </>
  );
};

