import React from "react";
import { Link } from "gatsby";
import styles from './teaser.module.css';
import BackgroundImage from "gatsby-background-image";
import { StaticQuery, graphql } from 'gatsby';

const Teaser = ({ data, post }) => (
  <>
    <Link
      to={post.frontmatter.path}
      className={`${post.frontmatter.listingSize && styles[post.frontmatter.listingSize]} ${styles.listEntryLink}`}
    >
      <BackgroundImage
        template="article"
        className={styles.listEntry}
        fluid={post.frontmatter.coverImage ? post.frontmatter.coverImage.childImageSharp.fluid : data.file.childImageSharp.fluid}
        backgroundColor={`#999`}
      >
        <div className={styles.overlayFormat}>
          <h1 className={styles.permalink}>
            {post.frontmatter.title}
          </h1>
          {post.frontmatter.listingSize && <p className={styles.excerpt}>{post.excerpt}</p>}
          <time className={styles.date}>{post.frontmatter.date}</time>
        </div>
      </BackgroundImage>
    </Link>
  </>
);

export default props => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { eq: "placeholder.jpg" }) {
          childImageSharp {
            fluid(
              quality: 50,
              maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => <Teaser data={data} {...props} />}
  />
);
