import React from "react";
import { graphql } from 'gatsby';
import ArchiveTemplate from "../templates/archiveTemplate";

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  const stories = edges
    .filter(edge => !!edge.node.frontmatter.date); // Only show stories with a publication date.
  return <ArchiveTemplate stories={stories} />;
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 180)
          frontmatter {
            year: date(formatString: "YYYY")
            day: date(formatString: "D MMMM", locale: "nl")
            date(formatString: "D MMMM YYYY", locale: "nl")
            path
            title
            coverImage {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 50
                ) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            coverImagePosition
            listingSize
          }
        }
      }
    },
  }
`;
